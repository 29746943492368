<script setup lang="ts">
withDefaults(
  defineProps<{
    disable?: boolean
    loading?: boolean
  }>(),
  {
    disable: false,
    loading: false,
  }
)

const agencyStore = useAgencyStore()
</script>

<template>
  <q-btn
    v-bind="$attrs"
    :disable="disable"
    :loading="loading"
    :color="
      !agencyStore.customizations?.defaults?.okButtonBgColor
        ? 'primary'
        : undefined
    "
    :style="{
      backgroundColor: `${agencyStore.customizations?.defaults?.okButtonBgColor} !important`,
      color: `${agencyStore.customizations?.defaults?.okButtonTextColor} !important`,
    }"
  >
    <slot />
  </q-btn>
</template>
